import React from 'react';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';
import {Interaction} from 'client/models/interactions/types';

import cssModule from './client-device-interactions.module.scss';

const b = bem('client-device-interactions', {cssModule});

type ClientDeviceInteractionsProps = {
  interactions: Interaction[];
};

const ClientDeviceInteractions: React.FC<ClientDeviceInteractionsProps> = ({interactions}) => {
  const langLanguages = useLanguage('LANGUAGES');
  const lang = useLanguage('CLIENT_STORES.LISTS.CLIENT_DEVICE_INTERACTIONS');

  type DefaultLanguageKey = keyof typeof langLanguages;

  return (
    <div className={b()}>
      <div className={b('list')}>
        {interactions?.map(({id, operation, from, to, default_language}) => (
          <div className={b('interaction')} key={id}>
            <div className={b('interaction-image-container')}>
              {operation?.operation_image?.url && (
                <img alt="" className={b('interaction-image')} src={operation?.operation_image?.url} />
              )}
            </div>

            <div>
              <div className={b('interaction-name')}>{operation?.name}</div>
              <div className={b('interaction-default-lang')}>
                {lang.DEFAULT_LANGUAGE}:&nbsp;
                {
                  langLanguages[
                    (default_language?.toUpperCase() ||
                      operation?.default_language?.toUpperCase()) as DefaultLanguageKey
                  ]
                }
              </div>
            </div>

            <DatetimeCell value={from} className={b('datetime', ['from'])} />
            <DatetimeCell value={to} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientDeviceInteractions;
