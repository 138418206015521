import {SourcesValues} from './types';

export default (values: SourcesValues, storeId: number) => {
  const result: Record<string, any> = [];

  Object.values(values).forEach((item) => {
    if (!item.checked && item.source_store_id) {
      result.push({id: item.source_store_id, _destroy: true});
    } else if (item.checked) {
      result.push({
        id: item.source_store_id,
        online_interaction_id: item.interaction_id,
        store_id: storeId,
        from: item.from,
        to: item.to,
        default_language: item.language,
      });
    }
  });

  return {source_store: result};
};
