import React, {useState, useEffect} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TimeInput, DatepickerInput, ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import {getTime, getDate, getValue} from './heleprs';

import cssModule from './datetime-picker.module.scss';

const b = bem('datetime-picker', {cssModule});

const DatetimePicker = (props) => {
  const {
    label,
    value,
    onChange,
    className,
    classNames,
    errorMessage,
    warningMessage = '',
    withSeconds,
    timePattern,
    onBlur,
    disabled,
    required = false,
    datepickerProps,
  } = props;
  const [time, setTime] = useState(getTime(value, withSeconds));
  const [date, setDate] = useState(getDate(value));

  useEffect(() => {
    setTime(getTime(value, withSeconds));
    setDate(getDate(value));
  }, [value, withSeconds]);

  const onDateChange = (dateArg) => {
    if (dateArg) {
      setDate(dateArg);
      const newValue = getValue(dateArg, time);
      setTime(getTime(newValue, withSeconds));
      onChange(newValue);
    } else {
      setDate('');
      setTime('');
      onChange();
    }
  };

  const handleTimeChange = (timeValue) => {
    setTime(timeValue);
    onChange(getValue(date, timeValue));
  };

  const handleBlur = () => {
    onBlur?.(getValue(date, time));
  };

  return (
    <div className={cn(className, b())}>
      {label && <span className={b('label')}>{label}</span>}
      <div className={b('row')}>
        <DatepickerInput
          {...datepickerProps}
          className={b('field', ['date'])}
          value={date}
          onChange={onDateChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
        <TimeInput
          key={date}
          className={b('field', {'time-long': withSeconds, 'time-short': !withSeconds})}
          value={time}
          pattern={timePattern}
          onChange={handleTimeChange}
          onBlur={handleBlur}
          withSeconds={withSeconds}
          disabled={disabled}
        />
      </div>
      {!errorMessage && required && <RequiredLabel className={classNames?.requiredLabel} />}
      {errorMessage && <ErrorMessage className={classNames?.errorMessage} errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && (
        <WarningMessage className={classNames?.warningMessage} warningMessage={warningMessage} />
      )}
    </div>
  );
};

DatetimePicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: TranslationJsx,
  className: PropTypes.string,
  classNames: PropTypes.shape({
    requiredLabel: PropTypes.string,
    errorMessage: PropTypes.string,
    warningMessage: PropTypes.string,
  }),
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  timePattern: PropTypes.string,
  withSeconds: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  datepickerProps: PropTypes.object,
};

DatetimePicker.defaultProps = {
  label: '',
  className: '',
  errorMessage: null,
  timePattern: '',
  withSeconds: true,
  disabled: false,
};

export default DatetimePicker;
