import React from 'react';

import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import CheckboxField from 'client/common/fields/checkbox-field';
import DatetimePickerField from 'client/common/fields/datetime-picker-field';
import SelectLanguageField from 'client/common/fields/select-language-field';
import Offscreen from 'client/common/offscreen';

import Icon from 'client/components/common/icon';

import {SourcesErrors, SourcesValues} from 'client/components/client-stores/modals/client-stores-sources-modal/types';
import {INTERACTION_STATUSES} from 'client/models/interactions/constants';
import {Interaction} from 'client/models/interactions/types';
import {Operation} from 'client/models/operations/types';

import cssModule from './interaction-item.module.scss';

const b = bem('interaction-item', {cssModule});

type InteractionItemProps = {
  interaction: Interaction;
  operation: Operation;
  formValues: SourcesValues;
  errors: SourcesErrors;
};

const InteractionItem: React.FC<InteractionItemProps> = (props) => {
  const {interaction, operation, formValues, errors} = props;
  const lang = useLanguage('CLIENT_STORES.MODALS.CLIENT_STORES_SOURCES_MODAL');
  const [open, toggleOpen] = useToggle(false);

  const disabled = !formValues[`i${interaction.id}`].checked;

  const from = formValues[`i${interaction.id}`].from;
  const to = formValues[`i${interaction.id}`].to;

  const hasError = !!errors[`i${interaction.id}`];

  return (
    <div className={b()}>
      <div className={b('head')} onClick={toggleOpen}>
        <div className={b('checkbox-wrap')}>
          <CheckboxField
            name={`i${interaction.id}.checked`}
            label={interaction.name}
            inversionColor
            disabled={interaction.current_status !== INTERACTION_STATUSES.NEW}
            classNames={{label: hasError ? b('error') : ''}}
          />
          <Icon name={open ? 'arrow-up' : 'arrow-down'} />
        </div>
        {!open && (
          <p className={b('period', {error: hasError})}>
            {dateToString(from, 'DD/MM/YYYY HH:mm:ss')} - {dateToString(to, 'DD/MM/YYYY HH:mm:ss')}
          </p>
        )}
      </div>
      <Offscreen hidden={!open}>
        <div className={b('body')}>
          <div className={b('dates')}>
            <DatetimePickerField
              className={b('date-field')}
              name={`i${interaction.id}.from`}
              label={lang.FROM}
              disabled={disabled}
              datepickerProps={{
                disabledDayAfter: to,
              }}
            />
            <Icon name="arrow" className={b('period-arrow')} />
            <DatetimePickerField
              className={b('date-field')}
              name={`i${interaction.id}.to`}
              label={lang.TO}
              disabled={disabled}
              datepickerProps={{
                disabledDayBefore: from,
              }}
            />
          </div>
          <SelectLanguageField
            className={b('language-field')}
            name={`i${interaction.id}.language`}
            label={lang.DEFAULT_LANGUAGE}
            languages={operation.languages}
            disabled={disabled}
            simpleValue
            inputHeight="small"
          />
        </div>
      </Offscreen>
    </div>
  );
};

export default InteractionItem;
