import React from 'react';

import get from 'lodash/get';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {ToggleField, TextField, SelectField, NumberField, CheckboxField} from 'client/common/fields';
import ArticlePanel from 'client/common/panels/article-panel';
import LayoutPanel from 'client/common/panels/layout-panel';

import {
  PRIZE_TYPES,
  LOYALTY_COUPON_TYPES,
  PRIZE_CODE_TYPES,
  PRIZE_BARCODE_FORMAT,
} from 'client/components/prizes/constants';
import PrizeDiyValidityFieldset from 'client/components/prizes/fieldsets/prize-diy-validity-fieldset';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';
import {Prize} from 'client/models/prizes/types';

import {getPrizeTypeOptions} from './helpers';

import cssModule from './prize-diy-main-fieldset.module.scss';

const b = bem('prize-diy-main-fieldset', {cssModule});

type PrizeDiyMainFieldsetProps = {
  disabled: boolean;
  isEdit: boolean;
  nextPrize?: Prize;
  prevPrize?: Prize;
};

const PrizeDiyMainFieldset: React.FC<PrizeDiyMainFieldsetProps> = (props) => {
  const {disabled, nextPrize, prevPrize, isEdit} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_MAIN_FIELDSET');
  const client = useSelector(selectCurrentClient);
  const autotask = useSelector(selectAutotask);

  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);
  const {type: prizeType} = formValues?.main || {};

  const handlePrizeTypeChange = (type: string) => {
    let loyaltyOnlyValue = false;
    if (type === PRIZE_TYPES.LOYALTY) {
      loyaltyOnlyValue = true;
    } else if (type === PRIZE_TYPES.COUPON) {
      if ([formValues.ticket.visual_type, formValues.email.email_code_type].includes(PRIZE_CODE_TYPES.BAR_CODE)) {
        change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.EAN_8);
      } else {
        change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.NONE);
      }
    } else if (type === PRIZE_TYPES.COMMON) {
      change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.NONE);
    }

    change('main.loyalty_only', loyaltyOnlyValue);
  };

  const fullWinIsVisible =
    (!prevPrize && !nextPrize) || !nextPrize || prevPrize?.full_win || (!prevPrize?.full_win && nextPrize.full_win);
  const fullWinIsDisabled = isEdit && (!!prevPrize?.full_win || (nextPrize && !nextPrize?.full_win));

  return (
    <div className={b()}>
      <LayoutPanel className={b('panel')}>
        <div className={b('type-row')}>
          <SelectField
            label={lang.PRIZE_TYPE}
            name="main.type"
            inputHeight="small"
            className={b('prize-type')}
            options={getPrizeTypeOptions(client, lang)}
            disabled={disabled}
            onChange={handlePrizeTypeChange}
            simpleValue
          />
          {prizeType === PRIZE_TYPES.COUPON && (
            <span className={b('coupon-name')}>{get(formValues, 'coupon.internal_name')}</span>
          )}
          <div className={b('toggle-distribution')}>
            <span className={b('distribution-label')}>{lang.DISTRIBUTION}</span>
            <ToggleField name="main.distribution" disabled={client.auto_configuration && autotask.validated} rounded />
          </div>
        </div>

        {prizeType === PRIZE_TYPES.LOYALTY && (
          <div className={b('loyalty-fields')}>
            <NumberField name="main.loyalty_amount" label={lang.AMOUNT} required={true} inputHeight="small" />
            <TextField name="main.loyalty_unit" label={lang.UNIT} inputHeight="small" />
          </div>
        )}
        {prizeType === PRIZE_TYPES.COUPON && client.loyalty_program_id && (
          <SelectField
            label={lang.LOYALTY_MODE}
            name="main.loyalty_coupon_type"
            className={b('loyalty-mode')}
            options={[
              {label: lang.NONE, value: LOYALTY_COUPON_TYPES.NONE},
              {label: lang.OPTIONAL, value: LOYALTY_COUPON_TYPES.OPTIONAL},
              {label: lang.MANDATORY, value: LOYALTY_COUPON_TYPES.MANDATORY},
            ]}
            disabled={disabled}
            simpleValue
          />
        )}
        <PrizeDiyValidityFieldset prefix="main" validityType={formValues.main?.validity_type} disabled={disabled} />
        <div className={b('grid-fields')}>
          <NumberField
            name="main.level"
            label={lang.LEVEL}
            className={b('level-field')}
            inputHeight="small"
            disabled={client.auto_configuration && autotask.validated}
          />
          <div className={b('loyalty-field')}>
            {client.loyalty_program_id && (
              <CheckboxField label={lang.LOYALTY_ONLY} name="main.loyalty_only" disabled={disabled} />
            )}
          </div>
          {fullWinIsVisible && (
            <>
              <ToggleField
                name="main.full_win"
                label={lang.FULL_WIN}
                className={b('full-win-field')}
                labelPosition="left"
                disabled={fullWinIsDisabled || disabled}
                rounded
              />
              {formValues.main.full_win && (
                <NumberField
                  name="main.full_win_ratio"
                  label={lang.FULL_WIN_RATIO}
                  inputHeight="small"
                  labelPosition="left"
                  disabled={disabled}
                  inputClassName={b('full-win-ratio-field')}
                />
              )}
            </>
          )}
        </div>
      </LayoutPanel>
      <ArticlePanel className={b('panel')} title={lang.COMMUNICATION}>
        <div className={b('representation-row')}>
          <ToggleField
            name="main.email_send"
            label={lang.SEND_EMAIL}
            backgroundColor="current"
            borderColor="current"
            disabled={disabled}
            rounded
          />
          <ToggleField
            name="main.sms_send"
            label={lang.SEND_SMS}
            backgroundColor="current"
            borderColor="current"
            disabled={disabled}
            rounded
          />
          <ToggleField
            name="main.create_ticket"
            label={lang.CREATE_TICKET}
            backgroundColor="current"
            borderColor="current"
            disabled={disabled}
            rounded
          />
        </div>
      </ArticlePanel>
    </div>
  );
};

export default PrizeDiyMainFieldset;
