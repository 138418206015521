import {preparePrizeLocalOrders} from 'client/components/prizes/utils/preparePrizeLocalOrders';
import {Prize} from 'client/models/prizes/types';

import {PrizeCustomizationModalForm} from './types';

export default (values: PrizeCustomizationModalForm) => {
  return {
    prize_map: values.prizeMaps.map((prizeMap) => ({
      id: prizeMap.id,
      initial_stock: prizeMap.initial_stock,
      active: prizeMap.active,
      from: values.from,
      to: values.to,
    })),
  };
};

type MapPrizeOrderParams = {
  prizes: Prize[];
};

export const mapPrizeOrder = (values: PrizeCustomizationModalForm, {prizes}: MapPrizeOrderParams) => {
  return preparePrizeLocalOrders(
    prizes,
    values.prizeMaps.map(({prize}) => prize!),
  ).reduce<Record<number, number>>((acc, prize) => {
    acc[prize.id] = prize.order;

    return acc;
  }, {});
};
