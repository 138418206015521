import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectAutotaskTypes} from 'client/ducks/autotask/selectors';
import {createGame, updateGame, deleteGame} from 'client/ducks/games/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons/app-button';
import DatetimePickerField from 'client/common/fields/datetime-picker-field';
import SelectField from 'client/common/fields/select-field';
import TextField from 'client/common/fields/text-field';
import ToggleField from 'client/common/fields/toggle-field';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';
import Tag from 'client/common/tag';

import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {isGameDrawClosed} from 'client/models/prizes/helpers';
import {Game, Prize} from 'client/models/prizes/types';

import getInitialValues from './getInitialValues';
import mapFormValues from './mapFormValues';
import {DrawParametersValues} from './types';
import validateValues from './validateValues';

import cssModule from './draw-parameters-modal.module.scss';

const b = bem('draw-parameters-modal', {cssModule});

export const DrawParametersModalFormName = 'DrawParametersModalForm';

type DrawParametersModalProps = {
  onClose: () => void;
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
  editingData?: Game;
  disabled?: boolean;
  prizes?: Prize[];
};

const DrawParametersModal: ReduxFormFC<DrawParametersModalProps, DrawParametersValues> = (props) => {
  const {handleSubmit, onClose, onSave, editingData, onDelete, disabled, prizes} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('LOTTERY.MODALS.DRAW_PARAMETERS_MODAL');
  const params: {autotaskId: string} = useParams();
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [saving, toggleSaving] = useToggle(false);
  const [deleting, toggleDeleting] = useToggle(false);
  const {appendToastNotification} = useToast();
  const {isDevicesType, isOnlineType} = useSelector(selectAutotaskTypes);
  const operation = useSelector(selectOperation);

  const isEdit = !!editingData;

  const isClosed = isEdit && isGameDrawClosed(editingData.game_draws?.[0]);

  const isReadOnly = isClosed || disabled;

  const isOnlineOnly = operation.client_interface?.online && !operation.client_interface?.device;
  const isDeviceOnly = !operation.client_interface?.online && operation.client_interface?.device;

  const {formValues, invalid, change} = useReduxForm(DrawParametersModalFormName, {
    initialValues: getInitialValues(editingData, isOnlineOnly),
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const handleSave = async (values: DrawParametersValues) => {
    toggleSaving();
    const data = mapFormValues(values, Number(params.autotaskId), prizes, isOnlineOnly);
    try {
      if (isEdit) {
        await dispatch(updateGame(editingData.id, data));
      } else {
        await dispatch(createGame(data));
      }
    } catch {
      appendToastNotification({type: 'error', title: lang.ERRORS.ERROR});
    }
    await onSave();
    toggleSaving();
    onClose();
  };

  const handleDelete = async () => {
    toggleDeleteModal();
    toggleDeleting();
    try {
      if (isEdit) {
        await dispatch(deleteGame(editingData.id));
      }
    } catch {
      appendToastNotification({type: 'error', title: lang.ERRORS.ERROR});
    }
    if (onDelete) {
      await onDelete();
    }
    toggleDeleting();
    onClose();
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('buttons')}>
          {isEdit && !isReadOnly && (
            <>
              <ConfirmationModal
                show={showDeleteModal}
                onClose={toggleDeleteModal}
                onConfirm={handleDelete}
                title={lang.DELETE_DRAW}
                message={lang.DELETE_MESSAGE}
                buttonCancel={{
                  label: lang.CANCEL,
                  color: 'devices',
                  transparent: true,
                  size: 'small',
                }}
                buttonConfirm={{
                  label: lang.DELETE,
                  color: 'devices',
                  size: 'small',
                }}
                clientSide
              />
              <AppButton
                label={lang.DELETE_DRAW}
                onClick={toggleDeleteModal}
                color="error"
                transparent
                loading={deleting}
                disabled={saving}
              />
            </>
          )}
          <AppButton label={isReadOnly ? lang.CLOSE : lang.CANCEL} onClick={onClose} transparent />
          {!isReadOnly && (
            <AppButton
              label={lang.SAVE}
              className={b('save-button')}
              disabled={invalid || deleting}
              loading={saving}
              submit
            />
          )}
          {!isReadOnly && invalid && <p className={b('save-message')}>{lang.ERRORS.FILL_ALL_FIELDS}</p>}
        </div>
        {isClosed && <Tag className={b('tag')} iconName="finished" color="finished" text={lang.CLOSED} />}
        <div className={b('row', {name: true, 'tag-above': isReadOnly})}>
          <TextField name="name" label={lang.DRAW_NAME} disabled={isReadOnly} required={true} />
          <DatetimePickerField
            name="draw_moment"
            label={lang.DRAW_MOMENT}
            withSeconds={false}
            disabled={isReadOnly}
            required={true}
          />
        </div>
        <div className={b('row', ['dates'])}>
          <DatetimePickerField
            name="from"
            label={isEdit ? lang.FROM_BY_DEFAULT : lang.FROM}
            withSeconds={false}
            disabled={isReadOnly}
            required={true}
          />
          <DatetimePickerField
            name="to"
            label={isEdit ? lang.TO_BY_DEFAULT : lang.TO}
            withSeconds={false}
            disabled={isReadOnly}
            required={true}
          />
        </div>
        <div className={b('row')}>
          <p className={b('row-title')}>{lang.LOSS_RESULT_ANNOUNCEMENT}</p>
        </div>
        <div className={b('row', ['loss'])}>
          <ToggleField
            name="send_email"
            label={lang.SEND_EMAIL}
            classNames={{label: b('custom-label')}}
            disabled={isReadOnly}
            rounded
          />
          <ToggleField
            name="send_sms"
            label={lang.SEND_SMS}
            classNames={{label: b('custom-label')}}
            disabled={isReadOnly}
            rounded
          />
        </div>
        <div className={b('row')}>
          <p className={b('row-title')}>{lang.SEGMENTATION}</p>
        </div>
        <div className={b('row', ['segmentation'])}>
          <div className={b('segmentation-labels')}>
            {isDevicesType && <p className={b('custom-label')}>{lang.DEVICE}</p>}
            {isOnlineType && <p className={b('custom-label')}>{lang.ONLINE}</p>}
          </div>
          <div className={b('segmentation-inputs')}>
            {isDevicesType && (
              <div className={b('device-row')}>
                <SelectField
                  name="device_segmentation"
                  className={b('segment-input')}
                  options={[
                    {value: DEVICE_SEGMENTATION.GLOBAL, label: lang.DEVICES_GLOBAL},
                    {value: DEVICE_SEGMENTATION.PER_PLACE, label: lang.PLACE},
                    ...(operation.devices_count
                      ? [{value: DEVICE_SEGMENTATION.PER_DEVICE, label: lang.PER_DEVICE}]
                      : []),
                    {value: DEVICE_SEGMENTATION.NONE, label: lang.NONE},
                  ]}
                  disabled={isReadOnly}
                  simpleValue={true}
                  required={true}
                />
                {formValues.device_segmentation === DEVICE_SEGMENTATION.PER_PLACE && !isDeviceOnly && (
                  <ToggleField
                    name="hybrid"
                    label={lang.HYBRID_PER_STORE}
                    className={b('hybrid-toggle')}
                    classNames={{label: b('custom-label')}}
                    disabled={isReadOnly}
                    onChange={() => change('online_segmentation', ONLINE_SEGMENTATION.NONE)}
                    rounded
                  />
                )}
              </div>
            )}
            {isOnlineType && (
              <SelectField
                name="online_segmentation"
                className={b('segment-input')}
                options={[
                  {value: ONLINE_SEGMENTATION.GLOBAL, label: lang.ONLINE_GLOBAL},
                  {value: ONLINE_SEGMENTATION.PER_CHANNEL, label: lang.PER_CHANNEL},
                  {value: ONLINE_SEGMENTATION.PER_CAMPAIGN, label: lang.PER_CAMPAIGN},
                  {value: ONLINE_SEGMENTATION.NONE, label: lang.NONE},
                  isOnlineType && !isDevicesType
                    ? {
                        label: lang.STORE,
                        value: ONLINE_SEGMENTATION.STORE,
                      }
                    : null,
                ].filter((i) => i)}
                disabled={isReadOnly}
                simpleValue={true}
                required={true}
                onChange={() => change('hybrid', false)}
              />
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<DrawParametersModalProps, DrawParametersValues>({
  form: DrawParametersModalFormName,
})(DrawParametersModal);
