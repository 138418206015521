import {DEVICE_SEGMENTATION, ONLINE_SEGMENTATION} from 'client/components/lottery/constants';
import {Prize} from 'client/models/prizes/types';

import {DrawParametersValues} from './types';

const mapValuesForOnlineStore = (values: DrawParametersValues, isOnlineOnly?: boolean) => {
  if (!isOnlineOnly) {
    return {};
  }

  if (values.online_segmentation === ONLINE_SEGMENTATION.STORE) {
    return {
      online_level: ONLINE_SEGMENTATION.NONE,
      geo_level: DEVICE_SEGMENTATION.PER_PLACE,
      hybrid: true,
    };
  }

  return {
    online_level: values.online_segmentation,
    geo_level: DEVICE_SEGMENTATION.NONE,
    hybrid: false,
  };
};

export default (values: DrawParametersValues, autotaskId: number, prizes?: Prize[], isOnlineOnly?: boolean) => {
  const hybrid = values.device_segmentation === DEVICE_SEGMENTATION.PER_PLACE && values.hybrid;

  const mappedPrizes = prizes
    ? {
        prizes: prizes.map((prize) => ({
          id: prize.id,
          geo_level: prize.local ? DEVICE_SEGMENTATION.PER_PLACE : values.device_segmentation,
          online_level: prize.local ? ONLINE_SEGMENTATION.NONE : values.online_segmentation,
          hybrid,
        })),
      }
    : {};

  return {
    game: {
      name: values.name,
      game_type: 'prize_draw',
      win_number: 2,
      is_for_client: true,
      default_from: values.from,
      default_to: values.to,
      default_draw_time: values.draw_moment,
      geo_level: values.device_segmentation || DEVICE_SEGMENTATION.NONE,
      online_level: values.online_segmentation || ONLINE_SEGMENTATION.NONE,
      automation_task_id: autotaskId,
      hybrid,
      game_draws: [
        {
          id: values.draw_id,
          name: values.name,
          default_from: values.from,
          default_to: values.to,
          default_draw_time: values.draw_moment,
          loss_email: values.send_email,
          loss_sms: values.send_sms,
        },
      ],
      ...mappedPrizes,
      ...mapValuesForOnlineStore(values, isOnlineOnly),
    },
  };
};
