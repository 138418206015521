import {GetPrizeMapsLevelType} from 'client/ducks/games/actions';

import {isEditablePrizeMap} from 'client/components/prizes/helpers';
import {Game, PrizeMap} from 'client/models/prizes/types';

import {LotteryPrizeCustomizationPrizeMap, LotteryPrizeCustomizationValues} from './types';

type InitialParams = {
  game: Game;
  prize_maps: PrizeMap[];
  level: GetPrizeMapsLevelType;
  sourceIds: number[];
};

export default (params: InitialParams): LotteryPrizeCustomizationValues => {
  const {game, prize_maps, level, sourceIds} = params;

  return {
    id: game.id,
    prize_maps: prize_maps
      .sort((a, b) => a.prize!.order - b.prize!.order)
      .reduce<LotteryPrizeCustomizationPrizeMap[]>((acc, prizeMap) => {
        const prev = acc[acc.length - 1];

        if (prev && prev.prize_id === prizeMap.prize_id && prev.prize && prizeMap.prize) {
          prev.current_stock += prizeMap.current_stock;
          prev.initial_stock += prizeMap.initial_stock;
          acc[acc.length - 1] = prev;
          return acc;
        }

        acc.push({
          ...prizeMap,
          editable: isEditablePrizeMap(prizeMap, sourceIds, level),
        });
        return acc;
      }, []),
    game_draws:
      game.game_draws?.map((draw) => ({
        id: draw.id,
        default_from: draw.default_from,
        default_to: draw.default_to,
        default_draw_time: draw.default_draw_time,
        loss_email: draw.loss_email,
        loss_sms: draw.loss_sms,
      })) || [],
  };
};
