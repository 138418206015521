import React, {useEffect, useMemo} from 'react';

import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {getCampaigns, updateSourceStores} from 'client/ducks/client-stores/actions';

import AppButton from 'client/common/buttons/app-button';
import Modal from 'client/common/modals/modal';

import Spinner from 'client/components/common/spinner';

import {Operation} from 'client/models/operations/types';

import CampaignItem from './campaign-item';
import getInitialValues from './getInitialValues';
import {filterCampaigns} from './helpers';
import mapFormValues from './mapFormValues';
import {SourcesValues} from './types';
import validateValues from './validateValues';

import cssModule from './client-stores-sources-modal.module.scss';

const b = bem('client-stores-sources-modal', {cssModule});

const formName = 'ClientStoresSourcesForm';

type ClientStoresSourcesModalProps = {
  onClose: () => void;
  onSave: () => void;
  storeId: number;
  interfaceId: number;
  operation: Operation;
};

const ClientStoresSourcesModal: ReduxFormFC<ClientStoresSourcesModalProps, SourcesValues> = (props) => {
  const {onClose, onSave, interfaceId, storeId, operation, form, handleSubmit, initialized} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('CLIENT_STORES.MODALS.CLIENT_STORES_SOURCES_MODAL');
  const [saving, toggleSaving] = useToggle(false);

  const {formValues, initialize, invalid, errors} = useReduxForm(form, {
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const {data: {campaigns: rawCampaigns} = {campaigns: []}, loading} = useReduxFetch({
    action: getCampaigns,
    actionArgs: {interfaceId},
  });

  const campaigns = useMemo(() => filterCampaigns(rawCampaigns), [rawCampaigns]);

  useEffect(() => {
    if (!initialized && campaigns.length) {
      const initialData = getInitialValues(campaigns, operation, storeId);
      initialize(initialData);
    }
  }, [campaigns, initialize, initialized, operation, storeId]);

  const handleSave = async (values: SourcesValues) => {
    toggleSaving();
    const body = mapFormValues(values, storeId);
    await dispatch(updateSourceStores({body}));
    await onSave();
    toggleSaving();
    onClose();
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('content')}>
          {loading && <Spinner centered />}
          {!loading &&
            campaigns.map((campaign) => (
              <CampaignItem
                key={campaign.id}
                campaign={campaign}
                operation={operation}
                formValues={formValues}
                errors={errors}
              />
            ))}
        </div>
        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL} transparent onClick={onClose} />
          <AppButton label={lang.OK} loading={saving} size="small" disabled={invalid} submit />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<ClientStoresSourcesModalProps, SourcesValues>({
  form: formName,
})(ClientStoresSourcesModal);
