import React, {useMemo, useRef, useState} from 'react';

import moment from 'moment';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectOperation} from 'client/ducks/operations/selectors';

import StoreDevicePeriodLangFieldset, {
  StoreDevicePeriodLangFieldsetType,
} from 'client/components/client-stores/fieldsets/store-device-period-lang-fieldset';
import {ClientStoreModalCheckItem} from 'client/components/client-stores/modals/components/client-store-modal-check-item';
import {StoreInteractionBody} from 'client/components/client-stores/types';
import ClientDeviceInteractions from 'client/components/devices/components/client-device-interactions';
import {hasIntersection} from 'client/components/devices/modals/device-diy-parameters-modal/helpers';
import {DeviceAffectation} from 'client/models/device-affectations';
import {findInteractionOfOperation, getNearestInteraction} from 'client/models/devices/utils';
import {INTERACTION_STATUSES} from 'client/models/interactions/constants';
import {Interaction} from 'client/models/interactions/types';
import {Translation} from 'client/models/language/types';

import {StoreDevicesModalChangingType, StoreDevicesModalErrorType} from '../types';

import cssModule from './store-device.module.scss';

type StoreDeviceProps = {
  affectation: DeviceAffectation;
  onChange: (type: StoreDevicesModalChangingType, affectation: DeviceAffectation, body: StoreInteractionBody) => void;
  onError: (affectationId: number, error?: StoreDevicesModalErrorType) => void;
};

const b = bem('store-device', {cssModule});

const renderTime = (interaction: Interaction) =>
  `${moment(interaction.from).format('DD/MM/YYYY HH:mm:ss')} – ${moment(interaction.to).format('DD/MM/YYYY HH:mm:ss')}`;

export const StoreDevice: React.FC<StoreDeviceProps> = (props) => {
  const {affectation, onChange, onError} = props;

  const lang = useLanguage('CLIENT_STORES.MODALS.ATTACH_DEVICES');
  const langErrors = useLanguage('CLIENT_STORES.ERRORS');

  const operation = useSelector(selectOperation);

  // the affectation is attached to the current store
  const editedInteractionRef = useRef(
    affectation.device?.interactions?.find(
      ({place_id, operation: intOperation}) => place_id === affectation.place_id && intOperation?.id === operation.id,
    ),
  );
  const editedInteraction = editedInteractionRef.current;

  const [attached, setAttached] = useState(!!editedInteraction);

  const [data, setData] = useState<StoreDevicePeriodLangFieldsetType>({
    from: (editedInteraction || operation)?.from || '',
    to: (editedInteraction || operation)?.to || '',
    default_language: (editedInteraction || operation)!.default_language!,
  });

  const operationInteraction = findInteractionOfOperation(affectation.device!, operation);
  const nearestInteraction = getNearestInteraction(affectation.device!.interactions || [], operation);
  const opInteractionToShow = operationInteraction || nearestInteraction;

  const linkedInteractions = useMemo(() => {
    return (
      affectation.device?.interactions?.filter((i) => {
        return i.operation && hasIntersection(i, data.from, data.to);
      }) || []
    );
  }, [affectation.device?.interactions, data.from, data.to]);

  const hasStartedInteraction =
    operationInteraction && operationInteraction?.current_status !== INTERACTION_STATUSES.NEW;

  const handleCheck = (isChecked: boolean) => {
    const body: StoreInteractionBody = {
      device_id: affectation.device_id,
      place_id: affectation.place_id,
      ...data,
    };

    // if this device was attached before changes, then reset state of this one.
    if (!!editedInteraction === isChecked) {
      onChange('reset', affectation, body);
    } else {
      if ('uncheck' && editedInteraction) {
        body.id = editedInteraction.id;
      }
      onChange(isChecked ? 'check' : 'uncheck', affectation, body);
    }

    setAttached(isChecked);
  };

  const handleChangeFields = (value: StoreDevicePeriodLangFieldsetType) => {
    setData(value);
    const body: StoreInteractionBody = {
      id: editedInteraction?.id,
      device_id: affectation.device_id,
      place_id: affectation.place_id,
      ...value,
    };
    onChange('check', affectation, body);
  };

  const error = useMemo(() => {
    const fromIsAfterTo = moment(data.from).isSameOrAfter(moment(data.to));
    const isOverlap =
      attached &&
      !!getNearestInteraction(
        !editedInteraction ? linkedInteractions : linkedInteractions.filter((i) => i.id !== editedInteraction!.id),
        data,
      );

    let result: Translation = '';
    if (!data.from || !data.to) {
      result = langErrors.FROM_TO_REQUIRED_ERROR;
    } else if (fromIsAfterTo) {
      result = langErrors.FROM_IS_AFTER_TO;
    } else if (isOverlap) {
      result = langErrors.CHANGE_PERIOD;
    }
    result ? onError(affectation.id, {type: fromIsAfterTo ? 'validation' : 'overlap'}) : onError(affectation.id);
    return result;
  }, [affectation.id, attached, data, editedInteraction, langErrors, linkedInteractions, onError]);

  return (
    <ClientStoreModalCheckItem
      checked={attached}
      disabled={!!hasStartedInteraction}
      onCheck={handleCheck}
      name={`affectation-${affectation.id}`}
      getLabel={({opened}) => (
        <div className={b('device', {error: !!error})}>
          <span>{affectation.device?.name}</span>
          {opInteractionToShow && !opened && (
            <span className={b('interaction-short')}>
              {!operationInteraction && (
                <strong
                  className={b('interaction-operation-name')}
                >{`${opInteractionToShow.operation?.name}: `}</strong>
              )}
              {renderTime(opInteractionToShow)}
            </span>
          )}
        </div>
      )}
    >
      <StoreDevicePeriodLangFieldset
        hideStarted={!!editedInteraction && editedInteraction.current_status !== INTERACTION_STATUSES.NEW}
        value={data}
        className={b('fields')}
        onChange={handleChangeFields}
        disabled={!attached}
        errorMessage={error}
      />
      {!!linkedInteractions?.length && (
        <>
          <p className={b('booked-operations-text')}>{lang.BOOKED_OPERATIONS}</p>
          <ClientDeviceInteractions interactions={linkedInteractions.slice(0, 3)} />
        </>
      )}
    </ClientStoreModalCheckItem>
  );
};
