import get from 'lodash/get';
import moment from 'moment';

import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import {ScheduleInstantWinConfiguration} from 'client/models/schedule-instant-win-configuration/types';

import {TIMESTAMPS_FIELD_NAME} from './index';

type Timestamp = {
  value: string;
  id: string;
};

const convertValuesToScheduleConfig = (values: ScheduleInstantWinConfiguration, isPrizeMap?: boolean) => {
  const timestamps = get(values, TIMESTAMPS_FIELD_NAME) as Timestamp[] | undefined;

  const newTimestamps =
    timestamps
      ?.map(({value}: Timestamp) => ({
        manual_time: moment(value, 'DD/MM/YYYY HH:mm:ss').toISOString(),
      }))
      .sort((a, b) => (a.manual_time < b.manual_time ? -1 : 0)) || [];

  const deletedTimestamps = values?.customList?.initialTimestamps?.map(({id}) => ({id, _destroy: true})) || [];

  return {
    schedule_instant_win_configuration: {
      [isPrizeMap ? 'prize_map_timestamps' : 'prize_timestamps']: [...deletedTimestamps, ...newTimestamps],
      schedule_type: SCHEDULE_TABS.CUSTOM_LIST,
      id: values?.customList?.configurationId || '',
      intraday_win: values?.customList?.intraday_win || false,
      next_day_win: values?.customList?.next_day_win || false,
    },
  };
};

export default convertValuesToScheduleConfig;
