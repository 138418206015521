import {Campaign} from 'client/models/campaign/types';
import {Operation} from 'client/models/operations/types';

import {SourcesValues} from './types';

export default (campaigns: Campaign[], operation: Operation, storeId: number) => {
  const result: SourcesValues = {};

  campaigns.forEach((campaign) => {
    campaign.interactions?.forEach((interaction) => {
      const sourceStore = interaction.source_stores?.find(
        (i) => i.store_id === storeId && i.online_interaction_id === interaction.id,
      );
      result[`i${interaction.id}`] = {
        interaction_id: interaction.id,
        source_store_id: sourceStore?.id,
        checked: !!sourceStore,
        from: sourceStore?.from || interaction.from,
        to: sourceStore?.to || interaction.to,
        language: sourceStore?.default_language || operation.default_language || '',
      };
    });
  });

  return result;
};
